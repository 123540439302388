<template>
  <v-container>
    <row-header action="non" :msg="msgRowHeader"></row-header>

    <v-row dense>
      <v-col>
        <v-form
            ref="form"
            v-model="valid"
        >
          <v-card>
            <user-component
                :user_model="userData"
                :roles="roles"
                :merchants="merchants"
                update="2"
            />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  :disabled="!valid"
                  color="success"
                  @click="saveUserData"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>

      <v-overlay
          :absolute="absolute"
          :value="overlay"
      >
        <v-card color="white">
          <v-card-text>
            <overlay-img></overlay-img>
          </v-card-text>
        </v-card>
      </v-overlay>

    </v-row>
  </v-container>
</template>

<script>
import RowHeader from "@/components/content/RowHeader";
import OverlayImg from "@/components/content/OverlayImg";
import UserComponent from '../../../components/page/users/UserComponent';
import {RoleUserList, UserData} from "./UserModel";
import {GetRequest, PutRequest, RequestToken} from "../../../store/services/users";

export default {
  name: "UserEditCurrent",
  components: {
    RowHeader,
    OverlayImg,
    UserComponent
  },
  data: () => ({
    msgRowHeader: {newUser: 'Back', newMsg: 'Update User'},
    valid:true,
    absolute: false,
    overlay: false,
    roles: new RoleUserList(),
    userData: new UserData(),
    confirm_password: '',
    merchants: [],
    RequiredRules: [
      v => !!v || 'Field cannot empty',
    ],
    emailRules: {
      required: value => !!value || "Email required",
      email: value => {
        const patern = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        return patern.test(value) || "Invalid Email"
      },
    },
    passwordRules: [
      (value) => (value && value.length >= 6) || 'minimum 6 characters.',
    ],
  }),
  async mounted() {
    if (this.$store.getters.isLoggedIn) {
      console.log('view page admin user edit');
      // alert(JSON.stringify(this.$route.params.id));
      await this.getUserData();
      await this.getMerchantData();
    }
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.userData.password === this.confirm_password) || 'Password must match'
    }
  },
  methods : {
    async getMerchantData() {
      this.loading = await this.$store.dispatch('req_data')
      try{
        let response = await GetRequest('merchants');
        this.merchants = response.data;
        this.loading = await this.$store.dispatch('req_success');
        return true;
      }catch(err){
        this.loading = await this.$store.dispatch('req_failed');
        alert('get merchant data failed ' + err.message);
        return false;
      }
    },
    async getUserData() {
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try {
        const response = await GetRequest('users/who-am-i');
        this.userData = response.data;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        if (e === 'Token expired') {
          const refreshToken = await this.refreshToken();
          if (refreshToken) {
            await this.getUserData();
          }
        } else {
          alert('Get user data failed ' + e.message);
        }
      }
    },

    async saveUserData() {
      const user_id = this.userData._id;
      delete this.userData._id;
      delete this.userData.created_at;
      delete this.userData.created_by;
      // delete this.userData.current_balance;
      this.userData.current_balance = Number(this.userData.current_balance);
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try {
        const response = await PutRequest('users/' + user_id, this.userData);
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        if (response.data && response.message === 'updated') {
          alert('success to updated data');
        } else {
          alert('failed to updated data');
        }
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        if (e === 'Token expired') {
          const refreshToken = await this.refreshToken();
          if (refreshToken) {
            await this.saveUserData();
          }
        } else {
          alert('Update user data failed ' + e.message);
        }
      }
    },
    async refreshToken() {
      try {
        await RequestToken();
        return true;
      } catch (e) {
        alert('error load refresh token');
        return false;
      }
    },
  },
}
</script>

<style scoped>

</style>
